/*Font*/
@import url('https://fonts.cdnfonts.com/css/balsamiq-sans-2');

/*tailwindcss utility classes*/
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .drop-down-menu {
    @apply absolute left-0 bg-white rounded-lg shadow-sm w-max mt-14 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:mt-4 transition-all duration-500 py-4 border border-gray-200;
  }
  .btn-accent {
    @apply tracking-wide mr-2 inline-block px-4 py-3 text-xs bg-opacity-100 text-primary-500 hover:text-primary-700 font-semibold leading-none border border-primary-200 hover:border-primary-300 rounded-md;
  }
  .btn-primary {
  @apply tracking-wide inline-block px-4 py-3 text-xs font-semibold leading-none bg-primary-500 hover:bg-primary-400 text-white rounded-md;
  }
  .btn-white {
    @apply tracking-wide inline-block py-4 px-8 text-xs text-gray-500 bg-opacity-100 hover:text-gray-600 text-center font-semibold leading-none bg-white rounded-md;
  }

  .card-slider {
    @apply border border-gray-100 bg-white max-w-sm rounded-md overflow-hidden shadow hover:shadow-md transition duration-300 p-8;
  }

  a {
    @apply text-primary-500 hover:text-primary-500 underline
  }

  html, body {
    @apply min-h-screen m-0 text-gray-900
  }

}
